<template>
  <div class="">
    <Modal
      :id="clickedId"
      :text="'Are You sure you want to delete this location ?'"
      :ok-button="'Delete'"
      :cancel-button="'Cancel'"
      :show="show"
      @result="modalResult"
      @hidden="show = false"
    />
    <div class="card full-page">
      <div class="card-header" />
      <div class="card-body inner-scroll">
        <div class="row">
          <div v-for="(item, i) in items" :key="i" class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div class="card card-shadow" style="max-height: 240px; border-radius: 5px">
              <google-map
                :zoom="12"
                :draggable="false"
                :location="location(item)"
                :styling="'width:100%; height:175px;border-radius:5px'"
              />

              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-10">
                    <h4
                      class="card-title font-weight-bolder font-small-3 mt-1 mb-1"
                      style="color: #0e1a1a"
                    >
                      {{ item.name }}
                    </h4>
                    <p class="card-text font-weight-bold font-small-2" style="color: #0e1a1a">
                      {{
                        item.details.length > 25 ? item.details.slice(0, 25) + '...' : item.details
                      }}
                    </p>
                  </div>
                  <div class="col-2">
                    <span>
                      <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item
                          :to="{ name: 'edit-location', params: { itemId: item.id } }"
                        >
                          <feather-icon icon="Edit2Icon" class="mr-50" />
                          <span>{{ $t('Edit') }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="showConfirmationModal(item.id)">
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span>{{ $t('Delete') }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </div>
                  <div class="col-12" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="!items.length && !loading" class="d-flex m-auto flex-column">
            <NotFound />
          </div>
        </div>
      </div>
      <div class="card-footer footer-shadow">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :to="{ name: 'create-location' }"
        >
          {{ $t('Add New Location') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import LocationService from '@/services/LocationService'
import { BDropdown, BDropdownItem, BButton } from 'bootstrap-vue'
import Modal from '@/components/Modal'
import Ripple from 'vue-ripple-directive'
import NotFound from '@/components/NotFound'

export default {
  name: 'Locations',
  components: {
    NotFound,
    Modal,
    BDropdown,
    BButton,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      LocationService: new LocationService(),
      items: [],
      show: false,
      loading: true,
      clickedId: 0,
    }
  },
  mounted() {
    this.LocationService.index().then(res => {
      this.items = res.data
      this.loading = false
    })
  },
  methods: {
    deleteLocation: function (id) {
      this.loading = true
      this.LocationService.delete(id)
        .then(res => {
          this.loading = false
          this.items.forEach((value, index) => {
            if (value.id === id) this.items.splice(index, 1)
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    modalResult(value) {
      this.show = false
      if (value) this.deleteLocation(this.clickedId)
    },
    showConfirmationModal(id) {
      this.show = true
      this.clickedId = id
    },
    location(item) {
      return { lat: +item.latitude, lng: +item.longitude }
    },
  },
}
</script>

<style scoped></style>
